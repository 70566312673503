// eslint-disable-next-line import/prefer-default-export
export var FormSteps;
(function (FormSteps) {
    FormSteps[FormSteps["Postcode"] = 0] = "Postcode";
    FormSteps[FormSteps["Bedrooms"] = 1] = "Bedrooms";
    FormSteps[FormSteps["PropertyValue"] = 2] = "PropertyValue";
    FormSteps[FormSteps["PeriodToList"] = 3] = "PeriodToList";
    FormSteps[FormSteps["AgentsSelector"] = 4] = "AgentsSelector";
    FormSteps[FormSteps["Details"] = 5] = "Details";
    FormSteps[FormSteps["NumberOfSteps"] = 6] = "NumberOfSteps";
})(FormSteps || (FormSteps = {}));
